import React from 'react'

function lefttextbar() {
  return (
    <div className='sm:w-[20%] sm:m-[2.5%] items-center'>
        <h1 className='text-white text-center  sm:block hidden -rotate-90 tracking-[5px]'>
         Let's Make it work's
        </h1>
    </div>
  )
}
export default lefttextbar
